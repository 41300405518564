<template>
    <div @keyup.down="handleFocus" @keyup.esc="triggerBlur" @keyup.enter="onSelectSupplier(suppliers[0])">
        <el-input
            ref="searchInput"
            v-model="query"
            class="relative"
            size="medium"
            placeholder="Sök leverantör"
            @input="inputChange"
            @blur="handleBlur"
            v-validate="required === 'true' ? 'required' : ''"
            data-vv-delay="1000"
            name="leverantör"
            :error="errors.first('leverantör')"
        />
        <span @click="iconClick()" class="absolute cursor-pointer mt-2 -ml-6" style=" color: rgba(65, 87, 112)"><i class="fa-regular fa-pen-to-square" title="Uppdatera Leverantör"/></span>
        <div
            v-if="suppliers.length != 0"
            ref="supplierList"
            class="bg-white border font-normal rounded-b p-3 cursor-pointer border-solid z-50 max-h-80 absolute overflow-y-auto"
            style="width:500px; border-color: #cadeea"
        >
            <div ref="supplierListOption" v-for="(supplier, key) in suppliers" :key="key" class="pt-2" @mousedown="getSupplierDetails(supplier)">
                <span :class="{ 'bg-gray-100': key === selectedIndex }" @click="onSelectSupplier(supplier)">{{ supplier.name }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../supplierInvoice.api";

export default {
    data() {
        return {
            focus: false,
            query: "",
            timer: null,
            suppliers: [],
            selectedSupplierLocal: {},
            selectedIndex: 0,
        };
    },

    props: {
        selectedName: {
            type: String,
        },

        required: {
            type: String,
            default: "false",
        },
    },

    mounted() {
        this.$watch(propValue => {
            this.query = propValue.selectedName;
        });
    },

    methods: {
        getSupplierDetails(supplier) {
            this.onSelectSupplier(supplier);
        },

        iconClick() {
            this.$emit("iconClick");
        },

        handleBlur() {
            this.focus = false;
            this.suppliers = [];
            this.selectedIndex = 0;
            this.$refs.searchInput.blur();
            window.removeEventListener("keydown", this.traverseOptions);
        },

        triggerBlur() {
            this.handleBlur();
        },

        traverseOptions(e) {
            switch (e.keyCode) {
                case 27: // esc
                    e.preventDefault();
                    this.triggerBlur();
                    break;
                case 13: // enter
                    e.preventDefault();
                    console.log("🚀 ~ file: supplierSearch.vue ~ line 87 ~ traverseOptions ~ this.suppliers[this.selectedIndex]", this.suppliers[this.selectedIndex]);

                    this.onSelectSupplier(this.suppliers[this.selectedIndex]);
                    this.handleBlur();
                    break;
                case 9: // tab
                    e.preventDefault();
                    this.onSelectSupplier(this.suppliers[this.selectedIndex]);
                    this.handleBlur();
                    break;
                case 40: // down arrow
                    e.preventDefault();
                    this.traverseDown();
                    break;
                case 38: // up arrow
                    e.preventDefault();
                    this.traverseUp();
                    break;
                default:
                    break;
            }
        },

        scrollToElement(element) {
            const dropdown = this.$refs.supplierList;
            const scrollTop = dropdown.scrollTop;
            const scrollBounds = {
                top: scrollTop,
                bottom: scrollTop + dropdown.clientHeight,
            };

            const elementBounds = {
                top: element.offsetTop,
                bottom: element.offsetTop + element.clientHeight,
            };

            if (elementBounds.top < scrollBounds.top) {
                dropdown.scrollTop = elementBounds.top;
            } else if (elementBounds.bottom > scrollBounds.bottom) {
                dropdown.scrollTop = elementBounds.bottom - dropdown.clientHeight;
            }
        },

        traverseDown() {
            const length = this.suppliers.length;
            if (this.selectedIndex < length - 1) this.selectedIndex++;
            this.scrollToElement(this.$refs.supplierListOption[this.selectedIndex]);
        },

        traverseUp() {
            if (this.selectedIndex > 0) this.selectedIndex--;
            this.scrollToElement(this.$refs.supplierListOption[this.selectedIndex]);
        },

        onSelectSupplier: function(e) {
            this.focus = false;
            this.selectedSupplierLocal = e;
            this.suppliers = [];
            this.$emit("on-selected", this.selectedSupplierLocal);
        },

        handleFocus() {
            this.focus = true;
            // this.$refs.searchInput.select();
            window.addEventListener("keydown", this.traverseOptions);
        },

        inputChange() {
            clearInterval(this.timer);
            if (this.query === "") {
                this.$emit("on-selected", this.query);
            } else {
                this.timer = setTimeout(async () => {
                    this.suppliers = await Api.getSuppliersList(this.query);
                }, 700);
            }
        },
    },
};
</script>
